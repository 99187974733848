import { graphql, useStaticQuery } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import gsap from "gsap";
import React, { useEffect, useRef, useState } from "react";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Modal from "react-bootstrap/Modal";
import Row from "react-bootstrap/Row";
import { Helmet } from "react-helmet";
import { MdMovie, MdOutlineClear } from "react-icons/md";
import ReactPlayer from "react-player";
import ContactForm from "../components/contactForm";
import Layout from "../components/layout";
import ReviewSection from "../components/review-section";
import useOnScreen from "../components/use-on-screen";
import { checkPropertiesForNull } from "../utils/object";
import { SafeHtmlParser } from "../components/safe-html-parser";
import LazyLoad from "react-lazyload";

const AboutPage = () => {
  const refTb = useRef();
  const tbShow = useOnScreen(refTb, "0px");

  useEffect(() => {
    if (tbShow === true) {
      gsap.to(`.lower-anim`, {
        transform: "translate(0,0)",
        opacity: 1,
        ease: "power1",
        duration: 1.5,
        delay: 0.2,
      });
    }
  }, [tbShow]);
  
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          siteUrl
        }
      }
      pageData: wpPage(slug: { eq: "about" }) {
        seoFieldGroups {
          metaTitle
          metaDescription
          localBusinessSchema
          openGraphDescription
          openGraphTitle
          productSchema
          image {
            altText
            publicUrl
            localFile {
              childImageSharp {
                gatsbyImageData(
                  formats: [AUTO, WEBP]
                  quality: 100
                  transformOptions: { cropFocus: CENTER, fit: CONTAIN }
                  layout: CONSTRAINED
                  placeholder: BLURRED
                )
                original {
                  width
                  height
                }
              }
            }
          }
        }
        aboutPageFieldGroups {
          aboutIntroSection {
            heading
            videoHeading
            video {
              altText
              mediaItemUrl
            }
            image {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    formats: [AUTO, WEBP]
                    quality: 100
                    transformOptions: { cropFocus: CENTER, fit: CONTAIN }
                    layout: CONSTRAINED
                    placeholder: BLURRED
                  )
                }
              }
            }
            bottomNoteHeading
            bottomNoteButtonTitle
          }
          aboutBottomTwoColumnSection {
            heading
            subHeading
            content
            circularImage {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    formats: [AUTO, WEBP]
                    quality: 100
                    transformOptions: { cropFocus: CENTER, fit: CONTAIN }
                    layout: CONSTRAINED
                    placeholder: BLURRED
                  )
                }
              }
            }
          }
          aboutContactSection {
            formHeading
            fieldGroupName
            buttonLabel
          }
          aboutFullWithImageSection {
            leftImage {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    formats: [AUTO, WEBP]
                    quality: 100
                    transformOptions: { cropFocus: CENTER, fit: CONTAIN }
                    layout: CONSTRAINED
                    placeholder: BLURRED
                  )
                }
              }
            }
            rightImage {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    formats: [AUTO, WEBP]
                    quality: 100
                    transformOptions: { cropFocus: CENTER, fit: CONTAIN }
                    layout: CONSTRAINED
                    placeholder: BLURRED
                  )
                }
              }
            }
          }
          aboutGoogleReviews {
            heading
            seeMoreText
            googleReviewLink
            backgroundImage {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    formats: [AUTO, WEBP]
                    quality: 100
                    transformOptions: { cropFocus: CENTER, fit: CONTAIN }
                    layout: CONSTRAINED
                    placeholder: BLURRED
                  )
                }
              }
            }
            googleIcon {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    formats: [AUTO, WEBP]
                    quality: 100
                    transformOptions: { cropFocus: CENTER, fit: CONTAIN }
                    layout: CONSTRAINED
                    placeholder: BLURRED
                  )
                }
              }
            }
          }
          aboutSecondTextOnlySection {
            heading
            content
          }
          aboutTextOnlySection {
            heading
            content
            belowImageContent
            fullWidthMobileImage {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    formats: [AUTO, WEBP]
                    quality: 100
                    transformOptions: { cropFocus: CENTER, fit: CONTAIN }
                    layout: CONSTRAINED
                    placeholder: BLURRED
                  )
                }
              }
            }
            fullWidthImage {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    formats: [AUTO, WEBP]
                    quality: 100
                    transformOptions: { cropFocus: CENTER, fit: CONTAIN }
                    layout: CONSTRAINED
                    placeholder: BLURRED
                  )
                }
              }
            }
          }
          aboutTwoColumnSection {
            content
            fieldGroupName
            heading
            image {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    formats: [AUTO, WEBP]
                    quality: 100
                    transformOptions: { cropFocus: CENTER, fit: CONTAIN }
                    layout: CONSTRAINED
                    placeholder: BLURRED
                  )
                }
              }
            }
          }
        }
      }
    }
  `);

  const {
    pageData: { seoFieldGroups, aboutPageFieldGroups },
  } = data;

  const {
    aboutIntroSection,
    aboutBottomTwoColumnSection,
    aboutContactSection,
    aboutFullWithImageSection,
    aboutGoogleReviews,
    aboutSecondTextOnlySection,
    aboutTextOnlySection,
    aboutTwoColumnSection,
  } = aboutPageFieldGroups;

  const siteUrl = data.site.siteMetadata.siteUrl;
  // const pluginImage = getImage(data.heroImg.childImageSharp.gatsbyImageData);
  const [show1, setShow1] = useState(false);

  const handleClose1 = () => setShow1(false);
  const handleShow1 = () => setShow1(true);

  const breadcrumb = {
    "@context": "http://schema.org",
    "@type": "BreadcrumbList",
    itemListElement: [
      {
        "@type": "ListItem",
        position: "1",
        name: "Home",
        item: {
          url: `${siteUrl}`,
          id: `${siteUrl}`,
        },
      },
      {
        "@type": "ListItem",
        position: "2",
        name: "About",
        item: {
          url: `${siteUrl}/about`,
          id: `${siteUrl}/about`,
        },
      },
    ],
  };

  return (
    <>
      <Helmet>
        {" "}
        <script type="application/ld+json">{JSON.stringify(breadcrumb)}</script>
      </Helmet>

      <GatsbySeo
        title={seoFieldGroups?.metaTitle}
        description={seoFieldGroups?.metaDescription}
        language="en"
        openGraph={{
          type: "website",
          url: `${siteUrl}/about`,
          title: `${seoFieldGroups?.openGraphTitle}`,
          description: `${seoFieldGroups?.openGraphDescription}`,
          images: [
            {
              url: `${seoFieldGroups?.image?.localFile.publicURL}`,
              width: `${seoFieldGroups?.image?.localFile.childImageSharp.original.width}`,
              height: `${seoFieldGroups?.image?.localFile.childImageSharp.original.height}`,
              alt: `${seoFieldGroups?.image?.altText}`,
            },
          ],
        }}
      />

      <div>
        <Layout>
          <Modal
            style={{ zIndex: 99999 }}
            size="lg"
            show={show1}
            onHide={handleClose1}
            centered
          >
            <Modal.Body
              style={{ borderRadius: "8px" }}
              className="bg-white"
              closeButton
            >
              <div
                style={{ borderRadius: "8px" }}
                className="   text-center position-relative"
              >
                <div className="position-absolute  end-0 top-0 p-lg-2">
                  <MdOutlineClear
                    style={{ cursor: "pointer" }}
                    className="fs-4 "
                    onClick={handleClose1}
                  />
                </div>
                <div className="pb-4 pt-5">
                  <h2 className="text-center pb-4 text-secondary-light ">
                    {aboutIntroSection?.aboutIntroSection ??
                      "What I can do for you"}
                  </h2>
                  <LazyLoad height={700} offset={100}>
                    <ReactPlayer
                      playing
                      controls
                      className="w-100 h-auto"
                      url={aboutIntroSection?.video.mediaItemUrl}
                    />
                  </LazyLoad>
                  
                </div>
                <div className="text-center">
                  <Button
                    onClick={handleClose1}
                    // onClick={showModal}
                    variant="outline-secondary-light"
                    className="w-100 w-md-auto px-6 text-center  py-2 secondary-light-link "
                  >
                    Close
                  </Button>
                </div>
              </div>
            </Modal.Body>
          </Modal>
          {aboutIntroSection &&
            !checkPropertiesForNull(aboutIntroSection, ["heading"]) && (
              <>
                <section className="p-5 pt-4">
                  <Container>
                    <Row>
                      <Col className="">
                        <h1 className="display-3 text-grey-med text-center text-md-start ">
                          <SafeHtmlParser
                            htmlContent={aboutIntroSection.heading}
                          />
                        </h1>
                      </Col>
                    </Row>
                  </Container>
                </section>

                <section className="position-relative mb-5">
                  <GatsbyImage
                    className="w-100"
                    image={
                      aboutIntroSection.image?.localFile.childImageSharp
                        .gatsbyImageData
                    }
                    alt={aboutIntroSection.image?.altText}
                  />
                  <Container
                    style={{ bottom: "-4rem", overflow: "hidden" }}
                    className="w-100 position-absolute    d-none d-md-block start-50 h-100 translate-middle-x "
                  >
                    <Row className="justify-content-end w-100 align-items-end h-100">
                      <Col className="text-end">
                        <div
                          style={{
                            borderRadius: "8px",
                          }}
                          className="bg-white lower-anim offscreen-top text-start d-inline-block px-4 py-4"
                        >
                          <h2 className="fs-4">
                            <SafeHtmlParser
                              htmlContent={aboutIntroSection?.bottomNoteHeading}
                            />
                          </h2>

                          <Button
                            onClick={handleShow1}
                            // onClick={showModal}
                            variant="primary"
                            className="w-100 text-center mt-3 py-2 w-100 w-md-auto px-5"
                          >
                            <div className="d-flex align-items-center w-100  justify-content-center">
                              <MdMovie className="me-2 fs-4" />
                              {aboutIntroSection.bottomNoteButtonTitle ??
                                "How can I help"}
                            </div>
                          </Button>
                        </div>
                      </Col>
                    </Row>
                  </Container>
                  <Container className="d-md-none bg-white px-4">
                    <Row className="">
                      <Col>
                        <div className="  py-4 ">
                          <h2 className="fs-4">
                            <SafeHtmlParser
                              htmlContent={aboutIntroSection?.bottomNoteHeading}
                            />
                          </h2>

                          <Button
                            onClick={handleShow1}
                            // onClick={showModal}
                            variant="primary"
                            className="w-100 text-center mt-3 py-2 w-100 w-md-auto px-5"
                          >
                            <div className="d-flex align-items-center w-100  justify-content-center">
                              <MdMovie className="me-2 fs-4" />
                              {aboutIntroSection.bottomNoteButtonTitle ??
                                "How can I help"}
                            </div>
                          </Button>
                        </div>
                      </Col>
                    </Row>
                  </Container>
                </section>
              </>
            )}
          {aboutTextOnlySection &&
            !checkPropertiesForNull(aboutTextOnlySection, ["heading"]) && (
              <>
              <section ref={refTb}>
                <Container>
                  <Row>
                    <Col lg={8}>
                      <h2 className=" display-5 text-grey-med  mb-4 ">
                        {aboutTextOnlySection?.heading}
                      </h2>

                      <SafeHtmlParser
                        htmlContent={aboutTextOnlySection?.content}
                      />
                    </Col>
                  </Row>
                </Container>
              </section>
              </>
            )}
          {aboutTextOnlySection.fullWidthImage && (
            <section className="my-5">
              <GatsbyImage
                className="w-100 d-none d-lg-block"
                image={
                  aboutTextOnlySection.fullWidthImage?.localFile.childImageSharp
                    .gatsbyImageData
                }
                alt={aboutTextOnlySection.fullWidthImage?.altText}
              />
              <GatsbyImage
                className="w-100 d-lg-none"
                image={
                  aboutTextOnlySection.fullWidthMobileImage?.localFile
                    .childImageSharp.gatsbyImageData
                }
                alt={aboutTextOnlySection.fullWidthMobileImage?.altText}
              />
            </section>
          )}
          {aboutTextOnlySection?.belowImageContent && (
            <section className="pb-5">
              <Container>
                <Row>
                  <Col lg={8}>
                    <SafeHtmlParser
                      htmlContent={aboutTextOnlySection?.belowImageContent}
                    />
                  </Col>
                </Row>
              </Container>
            </section>
          )}
          <ReviewSection
            heading={aboutGoogleReviews?.heading}
            seeMoreText={aboutGoogleReviews?.seeMoreText}
            googleIcon={aboutGoogleReviews?.googleIcon}
            reviewLink={aboutGoogleReviews?.googleReviewLink}
            backgroundImage={aboutGoogleReviews?.backgroundImage}
          />
          {aboutSecondTextOnlySection &&
            !checkPropertiesForNull(aboutSecondTextOnlySection, [
              "heading",
            ]) && (
              <section className="pt-5 pt-lg-7">
                <Container>
                  <Row>
                    <Col lg={8}>
                      <h2 className=" display-5  mb-4 text-grey-med">
                        {aboutSecondTextOnlySection?.heading}
                      </h2>
                      <SafeHtmlParser
                        htmlContent={aboutSecondTextOnlySection?.content}
                      />
                    </Col>
                  </Row>
                </Container>
              </section>
            )}
          <section
            style={{
              background:
                "linear-gradient(200deg, rgba(245, 249, 252, 0.00) 3.13%, #F5F9FC 51.56%, rgba(245, 249, 252, 0.00) 100%), linear-gradient(180deg, #F5F9FC 0%, #F5F9FC 0.01%, rgba(250, 252, 254, 0.00) 31.77%, rgba(250, 252, 254, 0.00) 65.63%, #F5F9FC 100%), radial-gradient(116.72% 75.58% at 101.01% 32.67%, rgba(255, 147, 140, 0.41) 2.08%, rgba(245, 249, 252, 0.41) 100%), radial-gradient(126.79% 78.09% at -2.67% 63.84%, rgba(116, 194, 218, 0.42) 2.08%, rgba(245, 249, 252, 0.42) 100%)",
            }}
            id="form"
            className="py-5 py-lg-7"
          >
            <Container>
              <Row className="justify-content-center">
                <Col lg={8} xl={7}>
                  <h2 className="display-4 mb-4 text-center">
                    {aboutContactSection?.formHeading ??
                      "Book a discovery call"}
                  </h2>
                  <ContactForm btnText={aboutContactSection?.buttonLabel} />
                </Col>
              </Row>
            </Container>
          </section>
          <section>
            <Container>
              {aboutTwoColumnSection &&
                !checkPropertiesForNull(aboutTwoColumnSection, ["heading"]) && (
                  <Row className="align-items-center gy-4 gy-lg-0 gx-lg-4 pb-4">
                    <Col
                      lg={{ span: 6, order: "last" }}
                      xl={{ span: 7, order: "last" }}
                    >
                      <GatsbyImage
                        className="w-100 image-about"
                        image={
                          aboutTwoColumnSection.image?.localFile.childImageSharp
                            .gatsbyImageData
                        }
                        alt={aboutTwoColumnSection.image?.altText}
                      />
                    </Col>
                    <Col lg={6} xl={5}>
                      <h2 className=" display-5 mb-4 text-grey-med ">
                        {aboutTwoColumnSection?.heading}
                      </h2>
                      <SafeHtmlParser
                        htmlContent={aboutTwoColumnSection?.content}
                      />
                    </Col>
                  </Row>
                )}
              {aboutFullWithImageSection &&
                !checkPropertiesForNull(aboutFullWithImageSection, [
                  "leftImage",
                ]) && (
                  <Row className=" gy-4 gy-lg-0 gx-lg-4 d-none d-lg-flex ">
                    <Col lg={8}>
                      <GatsbyImage
                        className="w-100 image-about"
                        image={
                          aboutFullWithImageSection.leftImage?.localFile
                            .childImageSharp.gatsbyImageData
                        }
                        alt={aboutFullWithImageSection.leftImage?.altText}
                      />
                    </Col>
                    <Col lg={4}>
                      <GatsbyImage
                        className="w-100 image-about"
                        image={
                          aboutFullWithImageSection.rightImage?.localFile
                            .childImageSharp.gatsbyImageData
                        }
                        alt={aboutFullWithImageSection.rightImage?.altText}
                      />
                    </Col>
                  </Row>
                )}
            </Container>
          </section>
          {aboutBottomTwoColumnSection &&
            !checkPropertiesForNull(aboutBottomTwoColumnSection, [
              "heading",
            ]) && (
              <section className="pb-5  py-xl-7">
                <Container>
                  <Row className="g-5 g-lg-6 align-items-center">
                    <Col md={4}>
                      <GatsbyImage
                        className="w-100"
                        image={
                          aboutBottomTwoColumnSection.circularImage?.localFile
                            .childImageSharp.gatsbyImageData
                        }
                        alt={aboutBottomTwoColumnSection.circularImage?.altText}
                      />
                    </Col>
                    <Col md={8}>
                      <h2 className=" fs-2 mt-4  ">
                        {aboutBottomTwoColumnSection?.heading}
                      </h2>
                      <h3 className="text-secondary fs-4">
                        {aboutBottomTwoColumnSection?.subHeading}
                      </h3>
                      <SafeHtmlParser
                        htmlContent={aboutBottomTwoColumnSection?.content}
                      />
                    </Col>
                  </Row>
                </Container>
              </section>
            )}
        </Layout>
      </div>
    </>
  );
};

export default AboutPage;
