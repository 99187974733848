import React, { useState } from "react";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import GoogleIcon from "../images/G Logo.svg";
import StarsIcon from "../images/Stars.svg";

const ReviewCard = ({ review }) => {
	var trimmedString = review.reviewBody.substr(0, 278);
	trimmedString =
		trimmedString.substr(
			0,
			Math.min(trimmedString.length, trimmedString.lastIndexOf(" "))
		) + "...";

	const [reviewBody, setReviewBody] = useState(review.reviewBody);
	const [linkText, setlinkText] = useState("read more");
	const [expanded, setExpanded] = useState(false);

	const handleClick = () => {
		console.log("clicked");
		if (!expanded) {
			setReviewBody(review.reviewBody);
			setlinkText("read less");
			setExpanded(true);
		} else {
			setReviewBody(trimmedString);
			setlinkText("read more");
			setExpanded(false);
		}
	};
	if (!expanded) setReviewBody(trimmedString);
	return (
		<Col lg={4}>
			<div className="text-white h-100">
				<Row>
					<Col>
						<Row>
							<Col xs={2} md={1} lg={2}>
								<img
									alt="Google Logo"
									className="  w-100"
									src={GoogleIcon}
								></img>
							</Col>
							<Col xs={10} md={11} lg={10}>
								<div className="set-height">
									{review.reviewBody.length <= 278 && (
										<p>{review.reviewBody}</p>
									)}
									{review.reviewBody.length > 278 && (
										<p>
											{reviewBody}
											<a
												style={{ cursor: "pointer" }}
												className=" ms-2 "
												onClick={handleClick}
											>
												{linkText}
											</a>
										</p>
									)}
								</div>
							</Col>
						</Row>
					</Col>
				</Row>

				<Row>
					<Col xs={12} className="text-center">
						<p className="fs-5 comfortaa-bold mt-2 mb-0 pb-0">
							{review.author}
						</p>
						<img alt="Stars" className="pb-4 mt-1" src={StarsIcon}></img>
					</Col>
				</Row>
			</div>
		</Col>
	);
};

export default ReviewCard;
