import axios from "axios";
import React, { useEffect, useState } from "react";
import { Container, Row } from "react-bootstrap";
import Carousel from "react-bootstrap/Carousel";
import Col from "react-bootstrap/Col";
import Background2 from "../images/background-2.svg";
import GoogleIcon from "../images/google-white.svg";
import ReviewCard from "./review-card";
import { GatsbyImage } from "gatsby-plugin-image";
import { BgImage } from "gbimage-bridge";

const ReviewSection = ({
  heading,
  seeMoreText,
  googleIcon,
  reviewLink,
  backgroundImage,
}) => {
  const [reviews, setReviews] = useState([]);

  useEffect(() => {
    axios
      .get(
        `https://www.local-marketing-reports.com/external/showcase-reviews/widgets/d9ea1c447506db4a43bd12532a5cae68ca943de2`
      )
      .then((res) => {
        const reviewData = res.data.results;
        setReviews(reviewData);
        setReviews(reviewData);
        document.getElementById("loading-spinner").style.display = "none";
        document.getElementById("no-reviews").style.display = "block";
      });
  });

  return (
    <BgImage
      className="py-5  position-relative bg-secondary bg-right-image"
      image={backgroundImage?.localFile.childImageSharp.gatsbyImageData}
      alt={backgroundImage?.altText}
      style={{
        backgroundPosition: "left top -4rem",
      }}
    >
      <Container style={{ zIndex: 3 }} className="position-relative">
        <Row>
          <Col>
            <h2 className="pb-5 text-white text-center display-5">
              {heading ?? "Reviews"}
            </h2>
          </Col>
        </Row>
        <Row className="g-5 g-xl-7 h-100 d-none d-lg-flex">
          {reviews
            .filter((item, index) => index < 3)
            .map((review, index) => (
              <ReviewCard review={review} />
            ))}
        </Row>
        <Row className="d-lg-none review-carousal">
          <Col>
            <div>
              <Carousel interval={3000}>
                {reviews
                  .filter((item, index) => index < 3)
                  .map((review, index) => (
                    <Carousel.Item>
                      <div className="pb-4 px-3">
                        <ReviewCard review={review} />
                      </div>
                    </Carousel.Item>
                  ))}
              </Carousel>
            </div>
          </Col>
        </Row>
        <Row>
          <Col className="text-center mt-4">
            <div className="d-md-flex align-items-center justify-content-center">
              <p className="fs-5 me-2 text-white roboto-medium pb-0 mb-0">
                {seeMoreText ?? "See more reviews on"}
              </p>{" "}
              <a
                target="_blank"
                rel="noreferrer"
                href={
                  reviewLink ??
                  "https://www.google.com/maps/place/Body+Good+Therapies/@51.3555487,0.5895298,17z/data=!4m8!3m7!1s0x47d8cd4c57c73cb3:0xaeaf19fd99d21dac!8m2!3d51.3555454!4d0.5921047!9m1!1b1!16s%2Fg%2F11h8dwn41w?entry=ttu"
                }
              >
                <GatsbyImage
                  image={googleIcon?.localFile.childImageSharp.gatsbyImageData}
                  alt="Google Logo"
                />
              </a>
            </div>
          </Col>
        </Row>
      </Container>
    </BgImage>
  );
};

export default ReviewSection;
